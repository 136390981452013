<template>
  <div class="container topCon">
    <div class="ztTop"><span class="ztTitle">补充材料</span></div>
    <div class="wrapContent">
      <div class="data-db">
        <el-form ref="form" :model="form" label-width="132px" :rules="formRules" size="medium">
          <el-form-item label="法人开户承诺函（选填）" prop="legal_person_commitment">
            <el-upload
                :auto-upload="true"
                :action="uploadUrl"
                :headers="upParams"
                class="avatar-uploader"
                :show-file-list="false"
                :on-success="handleIdLegalPersonSuccess"
            >
              <el-image fit="contain" v-if="legalPersonCommitment" :src="legalPersonCommitment" class="avatar"></el-image>
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
            <div class="el-form-info">
              <p>请上传法定代表人或负责人亲笔签署的开户承诺函扫描件（<a href="https://kf.qq.com/faq/191018yUFjEj191018Vfmaei.html" target="_blank">下载模板</a>）。亲笔签名承诺函内容清晰可变，不得有涂污，破损，字迹不清晰现象。</p>
            </div>
          </el-form-item>
          <el-form-item label="法人开户意愿视频（选填）" prop="legal_person_video">
            <div class="videoWrap">
              <el-upload
                  class="avatar-uploader"
                  :action="uploadUrl"
                  :headers="upParams"
                  :show-file-list="false"
                  :before-upload="beforeVideoUpload"
                  :on-success="handleSuccessVideo"
                  :on-remove="handleRemoveVideo"
                  :file-list="videoFileList"
                  accept=".mp4,.avi,.wmv,.mpeg,.mov,.mkv,.flv,.f4v,.m4v,.rmvb"
              >
                <video v-if="videoFileList && videoFileList.length" :src="`https://mzmpic.oss-cn-beijing.aliyuncs.com/${videoFileList[0]}`" width="100%" height="100%" controls>
                </video>
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
              <el-button @click="handleRemoveVideo">重置</el-button>
            </div>
            <div class="el-form-info">
              <p>根据实际审核情况，会额外要求商家提供。建议法人按以下话术录制“法人开户意愿视频”： </p>
              <p>“我是#商户名称#的法定代表人（或负责人），特此证明本公司申请的商户号为我司真实意愿开立且用于XX业务（或XX服务）。我司现有业务符合法律法规及腾讯的相关规定。”录制要求： </p>
              <p>1. 请用普通话录制以上视频；</p>
              <p>2. 本视频须采集到清晰正面人脸，请务必保持正脸朝向摄像头且不要遮挡面部；</p>
              <p>3. 请避免对录制原始图像和音频进行任何处理（如滤镜、美颜、变音及其他后期效果）；</p>
              <p>4. 支持上传5M以内的视频文件，格式可为可为avi、wmv、mpeg、mp4、mov、mkv、flv、f4v、m4v、rmvb；</p>
            </div>
          </el-form-item>
          <el-form-item label="补充说明（选填）" prop="business_addition_msg">
            <el-input v-model="form.businessAdditionMsg"></el-input>
            <div class="el-form-info">
              <p>根据驳回要求提供额外信息，如：业务模式不清晰时，请详细描述支付场景。  </p>
            </div>
          </el-form-item>
          <el-form-item label="补充材料（选填）" >
            <el-upload
                :auto-upload="true"
                :action="uploadUrl"
                :headers="upParams"
                :class="{
                  disabled: uploadDisabled,
                }"
                list-type="picture-card"
                :on-preview="handlePictureCardPreview"
                :on-remove="handleRemove"
                :on-success="handleimgsUrl"
                :file-list.sync="indoorPicFileList"
                accept="image/jpeg,image/png"
                :limit="5"
                ref="upload"
            >
              <i class="el-icon-plus"></i>
            </el-upload>
            <el-dialog :visible.sync="dialogVisible">
              <img width="100%" :src="dialogImageUrl" alt="">
            </el-dialog>
            <div class="el-form-info">
              <p>1、根据驳回要求提供额外信息，如：</p>
              <p> （1）业务模式不清晰时，需详细描述支付场景或提供相关材料（如业务说明/门店照/手持证件照等）</p>
              <p> （2）特殊业务要求提供相关的协议材料等</p>
            </div>
          </el-form-item>
        </el-form>
        <div class="bottomBtn">
          <el-button class="btn-defalut" @click="backToMerchantApply">返回</el-button>
          <el-button class="btn-defalut" @click="saveToDraft">保存草稿箱</el-button>
          <el-button class="success btn-defalut" @click="toNext">下一步</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {addAdditionInfo} from "../../../api/wxMerchant";
import {baseImgUrl} from "../../../utils/constant";

export default {
  name: "additionInfo",
  components: {},
  data() {
    let token = localStorage.getItem("token")
    let info = JSON.parse(localStorage.getItem('info'));
    return {
      dialogImageUrl: '',
      bankOptions:[],
      indoorPicFileList:[],
      subjectType:'',
      videoFileList:[],
      dialogVisible: false,
      form:{
      },
      merchantId:'',
      legalPersonCommitment:'',
      uploadUrl:this.$store.state.uploadingUrl,
      ossUrl: this.$store.state.ossUrl,
      upParams: {
        token: token
      },
      formRules: {

      }
    };

  },
  computed: {},
  filters:{

  },
  watch: {},
  mounted() {
    this.merchantId = this.$route.query.merchantId
    this.subjectType = this.$route.query.subjectType
  },
  created() {
    if (this.$router.history.current.query.isSourceAdmin) {
      localStorage.setItem('isSourceAdmin', this.$router.history.current.query.isSourceAdmin);
    }
  },
  methods: {
    handleIdLegalPersonSuccess(res,file){
      this.form.legalPersonCommitment = res.data
      this.legalPersonCommitment = this.ossUrl + this.form.legalPersonCommitment
    },
    beforeVideoUpload(file) {
      let is5M = file.size / 1024 / 1024 < 5
      if (!is5M) {
        this.$message.error({
          title: '视频上传失败',
          message: `视频大小不可超过5M`
        })
      }
      return is5M
    },
    handleSuccessVideo(response, file, fileList) {
      if(response.code!== 200) {
        this.$message({
          type: 'error',
          message: response.message
        })
        this.videoFileList = []
      }else  {
        this.videoFileList = [response.data]
        this.form.legalPersonVideo = this.videoFileList[0]
      }
    },
    handleRemoveVideo(file, fileList) {
      this.videoFileList = []
      this.form.legalPersonVideo = ''
    },
    handlePictureCardPreview(file){
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleRemove(file, fileList) {
        let reg = new RegExp(baseImgUrl, "g")
        let url = file.url.replace(reg, '')
        for (var i = 0; i < this.indoorPicFileList.length; i++) {
          if (this.indoorPicFileList[i] === url) {
            this.indoorPicFileList.splice(i, 1)
          }
        }
      this.form.businessAdditionPicsList = fileList
    },
    handleimgsUrl(response, file, fileList) {
      if (response.code === 200) {
        this.$message({
          message: '上传成功',
          type: 'success'
        });
        this.indoorPicFileList.push(response.data)
        this.form.businessAdditionPicsList = this.indoorPicFileList
      }else {
        this.$message({
          message: response.message,
          type: 'error'
        });
        this.imgsFileList = fileList.pop()
      }
    },
    toNext(){
      //跳转下个页面且将数据进行保存
      this.form.merchantId = this.merchantId
      addAdditionInfo(this.form).then(res => {
        if (res.code === 200) {
          this.$router.push({path:'/merchantApplyInfo',query:{'merchantId':this.merchantId}})
          this.$message({
            message: '操作成功',
            type: 'success'
          });
        }
      })
    },
    saveToDraft(){
      this.form.merchantId = this.merchantId
      addAdditionInfo(this.form).then(res => {
        if (res.code === 200) {
          this.$message({
            message: '操作成功',
            type: 'success'
          });
        }
      })
    },
    backToMerchantApply(){
      this.$message.closeAll()
      let than = this
      setTimeout(function () {
        than.$router.push({path:'/merchantApplyInfo',query:{merchantId:than.merchantId,isSee:than.isSee}})
      },0)
    }
  }
};
</script>

<style scoped>
.ztTitle{
  font-size: 18px;
}
.topCon {
  position: relative;

}
.ztTop{
  margin-top: 20px;
  margin-bottom: 20px;
}
.wrapContent{
  padding: 32px 24px;
  margin-bottom: 24px;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 0 4px -1px rgb(0 0 0 / 8%);
}
.data-hd{
  margin-bottom: 30px;
  height: 14px;
  line-height: 1;
  padding-left: 10px;
  border-left: 3px solid #00c250;
}
.desc_info{
  font-size: 12px;
  color: #999;
  margin-left: 15px;
}
.descWord{
  color: #999;
  margin-top: 10px;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 106px;
  line-height: 106px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
/deep/.el-upload--text {
  background-color: #fff;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  box-sizing: border-box;
  width: 193px;
  height: 109px;
  position: relative;
  overflow: hidden;
}
.avatar-uploader-icon[data-v-e66b3640] {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 106px;
  line-height: 106px;
  text-align: center;
}
.el-input {
  position: relative;
  display: inline-block;
  width: 27%;
}
/*/deep/ .el-input__inner{*/
/*  height: 50px;*/
/*}*/
/*/deep/.el-input__inner {*/
/*  !* height: 34px !important; *!*/
/*  height: 47px!important;*/
/*  line-height: 47px !important;*/
/*  font-size: 14px !important;*/
/*}*/
.el-form-item{
  margin-bottom: 25px;
}

.btn-defalut{
  color: #333;
  background-color: #eee;
  border-color: #eee;
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  cursor: pointer;
  border: 1px solid #e0e0e0;
  -webkit-appearance: none;
  text-align: center;
  box-sizing: border-box;
  outline: 0;
  margin: 0;
  transition: .1s;
  font-weight: 500;
  padding: 12px 40px;
  font-size: 14px;
  border-radius: 4px;
  margin-right: 30px;
}
.btn-defalut:hover{
  background: #f1f1f1;
  border-color: #f1f1f1;
  color: #333;
}

.success{
  color: #fff;
  background-color: #00c250;
  border-color: #00c250;
}
.success:hover {
  background: #33ce73;
  border-color: #33ce73;
  color: #fff;
}
.avatar {
width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  display: block;
}
.el-form-info{
  color: #999;
  font-size: 14px;
  line-height: 1.6;
  padding-top: 8px;
}
.videoWrap .el-icon-plus{
  font-size: 28px;
  color: #8c939d;
  line-height: 148px;
}
.videoWrap .el-upload--text {
  width: 148px;
  height: 148px;
}
</style>
